import { BrowserRouter, Navigate, Routes, Route, Link } from "react-router-dom";
import Home from "./pages/Home/Home";
import Nofound from "./pages/Notfound/Notfound";
import Resume from "./pages/Resume/Resume";
import CacheBuster from "./cacheBuster";
import About from "./pages/About/About";
import Skills from "./pages/Skills/Skills";
import Projects from "./pages/Projects/Projects";
import MyIcon from "./assets/images/WallpaperIcon.png";
import Contact from "./pages/Contacts/Contact";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from "react";
import "./App.css";

function QualityShitVol2() {
  window.location.href = 'https://music.youtube.com/playlist?list=PL9JsKH8d7-0NmK5JhL8pcs9M0zNqksYg0&feature=share';
  return null;
}
function GoaBookings() {
  window.location.href = 'https://drive.google.com/drive/folders/1XqpxLJLxTbr0oNy4BD9NgNep3bbdRjdf?usp=share_link';
  return null;
}
function Portfolio() {
  window.location.href = 'https://vaibhav.wtf/static/media/Portfolio.292547c3029b82b23d17.pdf';
  return null;
}
function SocialMediaQ() {
  window.location.href = 'https://vaibhavwtf.notion.site/Social-Media-Quotation-755428a4c41f43edb6cc462de88678a5';
  return null;
}
function LogoDesQ() {
  window.location.href = 'https://vaibhavwtf.notion.site/Logo-Design-Quotation-e925985a6eb24cbf80492d6a95a65710';
return null;
}
function Wallpapers() {
  window.location.href = 'https://wallpapers.vaibhav.wtf';
return null;
}

function App() {
  useEffect(()=>{
    toast("One Piece Collection Wallpapers OUT NOW!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      onClick: () => Wallpapers(),
      icon: ({theme, type}) =>  <img src={MyIcon} height="35px"/>,
      theme: "colored"});
  },[])
  return (
    <CacheBuster>
      {/* @ts-ignore */}
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null;
        if (!loading && !isLatestVersion) {
          alert("New Version Available!! Please refresh");
          refreshCacheAndReload();
        }
        return (
          <>
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/resume" element={<Resume />} />
                <Route path="/cv" element={<Resume />} />
                <Route path="/portfolio" element={<Portfolio />} />
                <Route path="/QualityShitVol2" element={<QualityShitVol2 />} />
                <Route path="/about" element={<About />} />
                <Route path="/skills" element={<Skills />} />
                <Route path="/projects" element={<Projects />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/logo-quotation" element={<LogoDesQ />} />
                <Route path="/sm-quotation" element={<SocialMediaQ />} />
                <Route path="/GoaBookings" element={<GoaBookings />} />

                <Route path="*" element={<Nofound />} />
              </Routes>
            </BrowserRouter>
            <ToastContainer />
          </>
        );
      }}
    </CacheBuster>
  );
}

export default App;
