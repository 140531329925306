import Navbar from "../../components/Navbar/Navbar";
import classes from "../Home/Home.module.css";

import Behance from "../../assets/images/contact_behance.svg";
import Dribbble from "../../assets/images/contact_dribbble.svg";
import GitHub from "../../assets/images/contact_github.svg";
import Instagram from "../../assets/images/contact_instagram.svg";
import LinkedIn from "../../assets/images/contact_linkedin.svg";
import Pinterest from "../../assets/images/contact_pinterest.svg";
import Twitter from "../../assets/images/contact_twitter.svg";
import Mail from "../../assets/images/contact_mail.svg";

import ImageTag from "../../components/ImageTag/ImageTag";
import Tag from "../../components/Tag/Tag";

import { motion, AnimatePresence } from "framer-motion";
import Logo from "../../assets/images/vaibhav.wtf.svg";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const Contact = () => {
  const [leave, setLeave] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();

  var tstart = 0;
  var deltaTouch = 0;
  const touch = (e) => {
    tstart = e.touches[0].clientY;
  };

  const swiping = (e) => {
    deltaTouch = tstart - e.changedTouches[0].clientY;
    if (deltaTouch < -10 && window.scrollY === 0) {
      setLeave(1);
      setTimeout(() => {
        navigate("/projects", { state: location.pathname });
      }, 1200);
    }
  };

  const handleScroll = (e) => {
    if (e.deltaY < 0) {
      setLeave(1);
      setTimeout(() => {
        navigate("/projects", { state: location.pathname });
      }, 1200);
    }
  };
  return (
    <div className="section">
      <Navbar leave={leave} />
      <div
        className={classes.contact_container}
        id="Contact"
        onWheel={(e) => handleScroll(e)}
        onTouchStart={(e) => touch(e)}
        onTouchEnd={(e) => swiping(e)}
      >
        <div style={{ height: "10vh" }}></div>
        <div className={classes.contact_flex}>
          <div className={classes.socials}>
            <motion.div
              initial={{
                opacity: 0,
                translateY: 0,
              }}
              animate={leave === 0 ? { opacity: 1 } : { opacity: 0 }}
              transition={{ duration: 0.3, delay: 0.2 }}
              className={classes.secondary_heading}
              style={{ marginTop: "0" }}
            >
              Connect me on
            </motion.div>
            <div className={classes.social_flex}>
              <ImageTag
                leave={leave}
                image={Instagram}
                index={1}
                link="https://www.instagram.com/vaibhav.wtf"
              />
              <ImageTag
                leave={leave}
                image={Behance}
                index={2}
                link="https://www.behance.net/vaibhav_kothi"
              />
              <ImageTag
                leave={leave}
                image={Dribbble}
                index={3}
                link="https://dribbble.com/vaibhav_kothi"
              />
              <ImageTag
                leave={leave}
                image={Pinterest}
                index={4}
                link="https://in.pinterest.com/vaibhavwtf/"
              />
              <ImageTag
                leave={leave}
                image={Twitter}
                index={5}
                link="https://twitter.com/vaibhavwtf/"
              />
              <ImageTag
                leave={leave}
                image={GitHub}
                index={6}
                link="https://github.com/vaibhavkothi"
              />
              <ImageTag 
                leave={leave}
                image={LinkedIn}
                index={7}
                link="https://www.linkedin.com/in/vaibhavkothi/" />
            </div>
            <motion.div
              initial={{
                opacity: 0,
                translateY: 0,
              }}
              animate={leave === 0 ? { opacity: 1 } : { opacity: 0 }}
              transition={{ duration: 0.3, delay: 0.2 }}
              className={classes.secondary_heading}
            >
              Get in touch
            </motion.div>
            <div className={classes.social_flex}>
              <Tag
                image={Mail}
                content="sampark@vaibhav.wtf"
                custom
                index={1}
                animate={
                  leave === 0
                    ? { opacity: 1, translateY: 0 }
                    : { opacity: 0, translateY: 50 }
                }
              />
            </div>
          </div>
          <motion.div
            className={classes.map_container}
            initial={{
              opacity: 0,
              translateY: 50,
            }}
            animate={
              leave === 0
                ? { opacity: 1, translateY: 0 }
                : { opacity: 0, translateY: 50 }
            }
            transition={{ duration: 0.3, delay: 0.6 }}
          >
            <iframe
              className={classes.map}
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d233.4125416410732!2d72.91285070678884!3d20.604371967699294!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be0c39f666fad43%3A0x6d5f133ade7b0267!2svaibhav.wtf!5e0!3m2!1sen!2sin!4v1655549638923!5m2!1sen!2sin"
              loading="lazy"
              allowFullScreen
            ></iframe>
          </motion.div>
        </div>
      </div>
      <div className={classes.footer}>
        <img src={Logo} alt="Logo" />
        <div className={classes.copyright}>
          © 2022 vaibhav.wtf. All Rights Reserved.
        </div>
      </div>
    </div>
  );
};

export default Contact;
