import React, { useEffect, useState } from "react";
import classes from "../Home/Home.module.css";
import Navbar from "../../components/Navbar/Navbar";
import Kothi from "../../assets/images/kothi_illustration.png";
import Button from "../../components/Button/Button";
import { motion, AnimatePresence } from "framer-motion";
import { Link, useNavigate, useLocation } from "react-router-dom";

import Portfolio from "../../assets/pdfs/Portfolio.pdf";

const About = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [leave, setLeave] = useState(0);

  // useEffect(() => {
  //   let timeout;
  //   window.addEventListener("wheel", (e) => {
  //     console.log(e.wheelDeltaY, "first");
  //     setLeave(1);
  //     if (e.wheelDeltaY < 0) {
  //       timeout = setTimeout(() => {
  //         navigate("/skills", { state: location.pathname });
  //       }, 1200);
  //     } else {
  //       timeout = setTimeout(() => {
  //         navigate("/", { state: location.pathname });
  //       }, 1200);
  //     }
  //   });
  //   return clearTimeout(timeout);
  // });
  const openInNewTab = (url) => {
    console.log(url);
    window.open(url, "_blank", "noopener,noreferrer");
  };

  var tstart = 0;
  var deltaTouch = 0;
  const touch = (e) => {
    tstart = e.touches[0].clientY;
  };

  const swiping = (e) => {
    deltaTouch = tstart - e.changedTouches[0].clientY;
    if (deltaTouch > 10) {
      setLeave(1);
      setTimeout(() => {
        navigate("/skills", { state: location.pathname });
      }, 1200);
    } else if (deltaTouch < -10) {
      setLeave(1);
      setTimeout(() => {
        navigate("/", { state: location.pathname });
      }, 1200);
    }
  };
  
  const handleWheel = (e) => {
    setLeave(1);
    if (e.deltaY > 0) {
      setTimeout(() => {
        navigate("/skills", { state: location.pathname });
      }, 1200);
    } else {
      setTimeout(() => {
        navigate("/", { state: location.pathname });
      }, 1200);
    }
  };
  return (
    <div
      className={[classes.about_container, "section"].join(" ")}
      id="About"
      onWheel={(e) => handleWheel(e)}
      onTouchStart={(e) => touch(e)}
      onTouchEnd={(e) => swiping(e)}
    >
      <Navbar color="#ffffff" leave={leave} />
      <div className={classes.about_content}>
        <motion.img
          initial={{ x: -500, opacity: 0 }}
          animate={leave === 0 ? { x: 0, opacity: 1 } : { x: -500, opacity: 0 }}
          transition={{
            type: "spring",
            duration: 1,
            bounce: 0.3,
            delay: 0.2,
          }}
          src={Kothi}
          alt="Kothi"
          id="kothi"
        />
        <div className={classes.right_content}>
          <motion.div
            className={classes.description}
            // ref={intro1}
            // id="content1"
            initial={{ y: 300, opacity: 0 }}
            animate={
              leave === 0 ? { y: 0, opacity: 1 } : { y: 300, opacity: 0 }
            }
            transition={{
              type: "spring",
              duration: 1,
              bounce: 0.3,
              delay: 0.3,
            }}
          >
            Professional UI/UX designer with over 3+ years experience in both
            UI/UX & Graphic Designing, looking for a challenging career
            opportunity for a Designer Position.
          </motion.div>
          <motion.div
            className={classes.description}
            // ref={content2}
            // id="content2"
            initial={{ y: 300, opacity: 0 }}
            animate={
              leave === 0 ? { y: 0, opacity: 1 } : { y: 300, opacity: 0 }
            }
            transition={{
              type: "spring",
              duration: 1,
              bounce: 0.3,
              delay: 0.4,
            }}
          >
            Offers and implements creative design solutions to enhance the user
            base and the aesthetics of a product.
          </motion.div>
          <motion.div
            className={classes.identity}
            // ref={content3}
            // id="content3"
            initial={{ y: 150, opacity: 0 }}
            animate={
              leave === 0 ? { y: 0, opacity: 1 } : { y: 150, opacity: 0 }
            }
            transition={{
              type: "spring",
              duration: 1,
              bounce: 0.3,
              delay: 0.5,
            }}
          >
            <div className={classes.identity_left}>Name:</div>
            <div className={classes.identity_right}>Vaibhav Kothi</div>
          </motion.div>
          <motion.div
            className={classes.identity}
            // ref={content3}
            // id="content3"
            initial={{ y: 150, opacity: 0 }}
            animate={
              leave === 0 ? { y: 0, opacity: 1 } : { y: 150, opacity: 0 }
            }
            transition={{
              type: "spring",
              duration: 1,
              bounce: 0.3,
              delay: 0.5,
            }}
          >
            <div className={classes.identity_left}>Based in:</div>
            <div className={classes.identity_right}>Gujarat, India</div>
          </motion.div>
          <motion.div
            className={classes.checkout}
            // ref={content4}
            // id="content4"
            initial={{ y: 100, opacity: 0 }}
            animate={
              leave === 0 ? { y: 0, opacity: 1 } : { y: 100, opacity: 0 }
            }
            transition={{
              type: "spring",
              duration: 1,
              bounce: 0.3,
              delay: 0.6,
            }}
          >
            Also Checkout
          </motion.div>
          <motion.div
            className={classes.button_flex}
            // ref={content4}
            // id="content4"
            initial={{ y: 50, opacity: 0 }}
            animate={leave === 0 ? { y: 0, opacity: 1 } : { y: 50, opacity: 0 }}
            transition={{
              type: "spring",
              duration: 1,
              bounce: 0.3,
              delay: 0.7,
            }}
          >
            <Link
              to="/resume"
              style={{
                textDecoration: "none",
              }}
            >
              <Button primary content="Resume" />
            </Link>
            <Button
              primary={false}
              content="Portflio"
              portfolio={true}
              onClick={() => openInNewTab(Portfolio)}
            />
            {/* <Button primary={false} content='Portfolio' /> */}
          </motion.div>
        </div>
        {/* <div className={classes.bottom_white_shadow}></div> */}
      </div>
    </div>
  );
};

export default About;
