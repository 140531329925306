import React, { ReactEventHandler } from 'react'
import classes from "./Button.module.css"

type Button = {
    primary: boolean,
    content: string,
    onClick?: ReactEventHandler
    portfolio?: boolean
}

const Button = (props: Button) => {
  return (
    <div className={classes.button} style={{
        backgroundColor: props.primary ? "#5637FA" : "transparent",
        color: props.primary ? "#fff" : "#5637FA",
    }} onClick={props.onClick}>
        {!props.portfolio && <div className={props.primary ? classes.content_primary : classes.content}>{props.content}</div>}
        {props.portfolio && <div className={props.primary ? classes.content_primary : classes.content}>{props.content}</div> }
    </div>
  )
}

export default Button