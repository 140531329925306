import classes from "../Home/Home.module.css";
import Navbar from "../../components/Navbar/Navbar";
import Kothi from "../../assets/images/kothi_illustration.png";
import Button from "../../components/Button/Button";
import { motion } from "framer-motion";
import { useNavigate, useLocation } from "react-router-dom";
import Tag from "../../components/Tag/Tag";

import Software01 from "../../assets/images/skills_illustrator.svg";
import Software02 from "../../assets/images/skills_blender.svg";
import Software03 from "../../assets/images/skills_davinci.svg";
import Software04 from "../../assets/images/skills_aftereffects.svg";
import Software05 from "../../assets/images/skills_premierepro.svg";
import Software06 from "../../assets/images/skills_figma.svg";
import Software07 from "../../assets/images/skills_xd.svg";
import Software08 from "../../assets/images/skills_photoshop.svg";
import Software09 from "../../assets/images/skills_lightroom.svg";
import Software10 from "../../assets/images/skills_audition.svg";

import Skills01 from "../../assets/images/skills_illustration.svg";
import Skills02 from "../../assets/images/skills_branding.svg";
import Skills03 from "../../assets/images/skills_printdesign.svg";
import Skills04 from "../../assets/images/skills_digitalmarketing.svg";
import Skills05 from "../../assets/images/skills_applicationdesign.svg";
import Skills06 from "../../assets/images/skills_photomanipulation.svg";
import Skills07 from "../../assets/images/skills_colorgrading.svg";
import Skills08 from "../../assets/images/skills_posterdesign.svg";
import Skills09 from "../../assets/images/skills_videoediting.svg";
import Skills10 from "../../assets/images/skills_visualeffects.svg";
import Skills11 from "../../assets/images/skills_sounddesign.svg";
import Skills12 from "../../assets/images/skills_productpackging.svg";
import Skills13 from "../../assets/images/skills_userexperience.svg";
import Skills14 from "../../assets/images/skills_webdesign.svg";
import Skills15 from "../../assets/images/skills_merchandising.svg";
import { useEffect, useRef, useState } from "react";

const softwares = [
  {
    image: Software01,
    description: "Adobe Illustrator",
  },
  {
    image: Software02,
    description: "Blender",
  },
  {
    image: Software03,
    description: "DaVinci Resolve",
  },
  {
    image: Software04,
    description: "Adobe After Effects",
  },
  {
    image: Software05,
    description: "Adobe Premiere Pro",
  },
  {
    image: Software06,
    description: "Figma",
  },
  {
    image: Software07,
    description: "Adobe XD",
  },
  {
    image: Software08,
    description: "Adobe Photoshop",
  },
  {
    image: Software09,
    description: "Adobe Lightroom",
  },
  {
    image: Software10,
    description: "Adobe Audition",
  },
];

const skills = [
  {
    image: Skills01,
    description: "Illustrations",
  },
  {
    image: Skills02,
    description: "Branding",
  },

  {
    image: Skills03,
    description: "Print Design",
  },
  {
    image: Skills04,
    description: "Digital Marketing",
  },
  {
    image: Skills05,
    description: "Application Design",
  },
  {
    image: Skills06,
    description: "Photo Manipulation",
  },
  {
    image: Skills07,
    description: "Color Grading",
  },
  {
    image: Skills08,
    description: "Poster Design",
  },

  {
    image: Skills09,
    description: "Video Editing",
  },
  {
    image: Skills10,
    description: "Visual Effects",
  },
  {
    image: Skills11,
    description: "Sound Design",
  },
  {
    image: Skills12,
    description: "Product Packging",
  },
  {
    image: Skills13,
    description: "User Experience",
  },
  {
    image: Skills14,
    description: "Web Design",
  },
  {
    image: Skills15,
    description: "Merchandising",
  },
];

const Skills = () => {
  const tags = useRef(null);
  const [leave, setLeave] = useState(0);
  const [wheelPosi, setWheelPosi] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  // console.log(location.pathname, location.state);

  // useEffect(() => {
  //   let timeout;
  //   window.addEventListener("wheel", (e) => {
  //     setLeave(1);
  //     setWheelPosi(e.wheelDeltaY);
  //     if (e.wheelDeltaY > 0) {
  //       timeout = setTimeout(() => {
  //         navigate("/about", { state: location.pathname });
  //       }, 1200);
  //     } else {
  //       timeout = setTimeout(() => {
  //         navigate("/projects", { state: location.pathname });
  //       }, 1200);
  //     }
  //   });
  //   return () => {
  //     clearTimeout(timeout);
  //   };
  // });

  var tstart = 0;
  var deltaTouch = 0;
  const touch = (e) => {
    tstart = e.touches[0].clientY;
  };

  const swiping = (e) => {
    deltaTouch = tstart - e.changedTouches[0].clientY;
    setWheelPosi(deltaTouch);
    console.log(window.scrollY);
    console.log(document.body.scrollHeight - window.innerHeight);
    if (deltaTouch < -10 && window.scrollY === 0) {
      setLeave(1);
      setTimeout(() => {
        navigate("/about", { state: location.pathname });
      }, 1200);
    } else if (deltaTouch > 10 && window.scrollY >= (document.body.scrollHeight - window.innerHeight - 10)) {
      setLeave(1);
      setTimeout(() => {
        navigate("/projects", { state: location.pathname });
      }, 1200);
    }
  };

  const handleScroll = (e) => {
    setLeave(1);
    setWheelPosi(e.deltaY);
    if (e.deltaY < 0) {
      setTimeout(() => {
        navigate("/about", { state: location.pathname });
      }, 1200);
    } else {
      setTimeout(() => {
        navigate("/projects", { state: location.pathname });
      }, 1200);
    }
  };
  return (
    <div
      className={[classes.skills_container, "section"].join(" ")}
      id="Skills"
      onWheel={(e) => handleScroll(e)}
      onTouchStart={(e) => touch(e)}
      onTouchEnd={(e) => swiping(e)}
    >
      <motion.div
        initial={{ opacity: 1 }}
        animate={leave === 0 ? { opacity: 0 } : { opacity: 1 }}
        transition={leave === 0 ? { delay: 0 } : { delay: 0.6 }}
        className={classes.skills_inner_container}
        style={{
          backgroundImage:
            location.state === "/about" && wheelPosi > 0
              ? `linear-gradient(
              to bottom,
              #3b88ff 0,
              #aeb8fe 54%,
              #dde1fd 79.9%,
              #fff 100%
            )`
              : "none",
        }}
      ></motion.div>
      <div className={classes.nav_space}>
        <Navbar color="#25004D" leave={leave} />
      </div>
      <div className={classes.skills_flex}>
        <motion.div
          initial={{
            opacity: 0,
            translateY: 0,
          }}
          animate={leave === 0 ? { opacity: 1 } : { opacity: 0 }}
          transition={{ duration: 0.3, delay: 0.2 }}
          className={classes.skills_heading}
        >
          Skills
        </motion.div>
        <div className={classes.tags_container}>
          {skills.map((skill, index) => {
            return (
              <Tag
                ref={tags}
                image={skill.image}
                content={skill.description}
                custom={false}
                index={index}
                animate={
                  leave === 0
                    ? { opacity: 1, translateY: 0 }
                    : { opacity: 0, translateY: 50 }
                }
              />
            );
          })}
        </div>
      </div>

      {/* <motion.div
        initial={{
          opacity: 0,
          translateY: 0,
        }}
        animate={leave === 0 ? { opacity: 1 } : { opacity: 0 }}
        transition={{ duration: 0.3, delay: 0.2 }}
        className={classes.skills_heading}
      >
        Software
      </motion.div>
      <div className={classes.tags_container}>
        {softwares.map((software, index) => {
          return (
            <Tag
              image={software.image}
              content={software.description}
              custom={false}
              index={index}
              animate={
                leave === 0
                  ? { opacity: 1, translateY: 0 }
                  : { opacity: 0, translateY: 50 }
              }
            />
          );
        })}
      </div> */}
    </div>
  );
};

export default Skills;
