import React from "react";
import classes from "./ImageTag.module.css";
import { motion } from "framer-motion";

type imageTag = {
  image: any;
  index: number;
  link: string;
  leave: number;
};
const ImageTag = (props: imageTag) => {
  return (
    <motion.a
      className={classes.imageTag_container}
      target="_blank"
      href={props.link}
      initial={{
        opacity: 0,
        translateY: 50,
      }}
      animate={
        props.leave === 0
          ? { opacity: 1, translateY: 0 }
          : { opacity: 0, translateY: 50 }
      }
      transition={{ duration: 0.3, delay: props.index * 0.08 }}
    >
      <img src={props.image} alt="Temp" />
    </motion.a>
  );
};

export default ImageTag;
