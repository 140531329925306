import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import classes from "../Home/Home.module.css";
import Navbar from "../../components/Navbar/Navbar";
import { debounce } from "../Home/helper";

import Project01 from "../../assets/images/Projects/01.jpg";
import Project02 from "../../assets/images/Projects/02.jpg";
import Project03 from "../../assets/images/Projects/03.jpg";
import Project04 from "../../assets/images/Projects/04.jpg";
import Project05 from "../../assets/images/Projects/05.jpg";
import Project06 from "../../assets/images/Projects/06.jpg";
import Project07 from "../../assets/images/Projects/07.jpg";
import Project08 from "../../assets/images/Projects/08.jpg";
import Project09 from "../../assets/images/Projects/09.jpg";
import Project10 from "../../assets/images/Projects/10.jpg";
import Project11 from "../../assets/images/Projects/11.jpg";
import Project12 from "../../assets/images/Projects/12.jpg";
import Project13 from "../../assets/images/Projects/13.jpg";
import Project14 from "../../assets/images/Projects/14.jpg";
import Project15 from "../../assets/images/Projects/15.jpg";
import Project16 from "../../assets/images/Projects/16.jpg";
import Project17 from "../../assets/images/Projects/17.jpg";
import Project18 from "../../assets/images/Projects/18.jpg";
import Project19 from "../../assets/images/Projects/19.jpg";
import Project20 from "../../assets/images/Projects/20.jpg";
import Project21 from "../../assets/images/Projects/21.jpg";
import Project22 from "../../assets/images/Projects/22.jpg";
import Project23 from "../../assets/images/Projects/23.jpg";
import Project24 from "../../assets/images/Projects/24.jpg";
import Project25 from "../../assets/images/Projects/25.jpg";
import Project26 from "../../assets/images/Projects/26.jpg";
import Project27 from "../../assets/images/Projects/27.jpg";
import Project28 from "../../assets/images/Projects/28.jpg";
import Project29 from "../../assets/images/Projects/29.jpg";
import Project30 from "../../assets/images/Projects/30.jpg";
import Project31 from "../../assets/images/Projects/31.jpg";
import Project32 from "../../assets/images/Projects/32.jpg";
import Project33 from "../../assets/images/Projects/33.jpg";
import Project34 from "../../assets/images/Projects/34.jpg";
import Project35 from "../../assets/images/Projects/35.jpg";
import Project36 from "../../assets/images/Projects/36.jpg";
import Project37 from "../../assets/images/Projects/37.jpg";
import Project50 from "../../assets/images/Projects/50.jpg";
import { useNavigate, useLocation } from "react-router-dom";
import { motion } from "framer-motion";

const projects = [
  {
    image: Project37,
    heading: "Delivery Solution",
    link: "https://www.behance.net/gallery/205295767/Delivery-Solution",
    buttonHeading: "Open Project",
  },
  {
    image: Project36,
    heading: "wallpapers.wtf",
    link: "https://www.behance.net/gallery/204799329/wallpaperswtf",
    buttonHeading: "Open Project",
  },
  {
    image: Project35,
    heading: "Email Marketing - Paytm Insider",
    link: "https://www.behance.net/gallery/196696111/Marketing-Email-Paytm-Insider",
    buttonHeading: "Open Project",
  },
  {
    image: Project34,
    heading: "Paytm Insider - Social Media",
    link: "https://www.behance.net/gallery/196416945/Paytm-Insider-Social-Media",
    buttonHeading: "Open Project",
  },
  {
    image: Project33,
    heading: "Paytm Movie Tickets - Social Media",
    link: "https://www.behance.net/gallery/195775263/Paytm-Movie-Tickets-Social-Media",
    buttonHeading: "Open Project",
  },
  {
    image: Project32,
    heading: "Landing Page - vdnj.info",
    link: "https://www.behance.net/gallery/194822425/vdnjinfo",
    buttonHeading: "Open Project",
  },
  {
    image: Project31,
    heading: "Internal Management Systems - Streebo Inc.",
    link: "https://www.behance.net/gallery/187870969/Internal-Management-Systems-Streebo-Inc",
    buttonHeading: "Open Project",
  },
  {
    image: Project30,
    heading: "Streebo Inc. - Marketing Assets",
    link: "https://www.behance.net/gallery/187844831/Streebo-Inc-Marketing-Assets",
    buttonHeading: "Open Project",
  },
 {
    image: Project29,
    heading: "Anime & Comic Headers",
    link: "https://www.behance.net/gallery/162575593/Anime-Comic-Headers",
    buttonHeading: "Open Project",
  },
 {
    image: Project28,
    heading: "Portfolio Website - vaibhav.wtf",
    link: "https://www.behance.net/gallery/165463563/Portfolio-Website",
    buttonHeading: "Open Project",
  },
  {
    image: Project27,
    heading: "Course Management App",
    link: "https://www.behance.net/gallery/160696855/Course-Management-App",
    buttonHeading: "Open Project",
  },
  {
    image: Project26,
    heading: "Career Reset: Show",
    link: "https://www.behance.net/gallery/160613823/Career-Reset-Show",
    buttonHeading: "Open Project",
  },
  {
    image: Project25,
    heading: "Power of Stocks Pro - Landing Page",
    link: "https://www.behance.net/gallery/152088631/Power-of-Stocks-Pro-Landing-Page",
    buttonHeading: "Open Project",
  },
  {
    image: Project24,
    heading: "POS - Merchandise",
    link: "https://www.behance.net/gallery/146843253/POS-Merchandise",
    buttonHeading: "Open Project",
  },
  {
    image: Project23,
    heading: "Agrimycil - Packaging",
    link: "https://www.behance.net/gallery/146742923/Agrimycil-Packaging",
    buttonHeading: "Open Project",
  },
  {
    image: Project22,
    heading: "Khyati Fashion",
    link: "https://www.behance.net/gallery/146675997/Khyati-Fashion",
    buttonHeading: "Open Project",
  },
  {
    image: Project21,
    heading: "IEEE CSIS 2020",
    link: "https://www.behance.net/gallery/143695213/CSIS-2020",
    buttonHeading: "Open Project",
  },
  {
    image: Project20,
    heading: "IEEE Inspiro 2020",
    link: "https://www.behance.net/gallery/121734807/INSPIRO-2020",
    buttonHeading: "Open Project",
  },
  {
    image: Project19,
    heading: "Khushi Academy",
    link: "https://www.behance.net/gallery/114524275/Khushi-Academy",
    buttonHeading: "Open Project",
  },
  {
    image: Project18,
    heading: "Mukesh Patel",
    link: "https://www.behance.net/gallery/113149979/Mukesh-Patel",
    buttonHeading: "Open Project",
  },
  {
    image: Project17,
    heading: "VP Financials",
    link: "https://www.behance.net/gallery/110684849/VP-Financials",
    buttonHeading: "Open Project",
  },
  {
    image: Project16,
    heading: "IEEE ICNL  2020",
    link: "https://www.behance.net/gallery/101955593/IEEE-ICNL-2020",
    buttonHeading: "Open Project",
  },
  {
    image: Project15,
    heading: "Trading Dudes",
    link: "https://www.behance.net/gallery/101915501/Trading-Dudes",
    buttonHeading: "Open Project",
  },
  {
    image: Project14,
    heading: "Spectrum 2020",
    link: "https://www.behance.net/gallery/100122145/SPECTRUM-2020",
    buttonHeading: "Open Project",
  },
  {
    image: Project13,
    heading: "Agrimycil - UI",
    link: "https://www.behance.net/gallery/99078811/Agrimycil-UIUX",
    buttonHeading: "Open Project",
  },
  {
    image: Project12,
    heading: "BeKnown.Digital",
    link: "https://www.behance.net/gallery/94270923/BeKnownDigital",
    buttonHeading: "Open Project",
  },
  {
    image: Project11,
    heading: "Global Warming - Poster",
    link: "https://www.behance.net/gallery/91144577/Poster",
    buttonHeading: "Open Project",
  },
  {
    image: Project10,
    heading: "IEEE AICSSYC'19",
    link: "https://www.behance.net/gallery/90702661/AICSSYC19",
    buttonHeading: "Open Project",
  },
  {
    image: Project09,
    heading: "CVM - Logo ReDesign",
    link: "https://www.behance.net/gallery/87474789/CVM-Logo",
    buttonHeading: "Open Project",
  },
  {
    image: Project08,
    heading: "IEEE INSPIRO 2.0",
    link: "https://www.behance.net/gallery/87455909/INSPIRO-20",
    buttonHeading: "Open Project",
  },
  {
    image: Project07,
    heading: "FreeFlow",
    link: "https://www.behance.net/gallery/87374333/Freeflow",
    buttonHeading: "Open Project",
  },
  {
    image: Project06,
    heading: "IEEE INSPIRO 2.0 - Logo Design",
    link: "https://www.behance.net/gallery/87360003/INSPIRO-20-Logofolio",
    buttonHeading: "Open Project",
  },
  {
    image: Project05,
    heading: "Wait Area",
    link: "https://www.behance.net/gallery/87327575/WaitArea",
    buttonHeading: "Open Project",
  },
  {
    image: Project04,
    heading: "IEEE Roll Up Banner",
    link: "https://www.behance.net/gallery/87318989/IEEE-Roll-Up-Banner",
    buttonHeading: "Open Project",
  },
  {
    image: Project03,
    heading: "Sunrise",
    link: "https://www.behance.net/gallery/78754971/Sunrise",
    buttonHeading: "Open Project",
  },
  {
    image: Project02,
    heading: "ADIT - Logo ReDesign",
    link: "https://www.behance.net/gallery/76747299/ADIT-Logo",
    buttonHeading: "Open Project",
  },
  {
    image: Project01,
    heading: "SPECTRUM'19",
    link: "https://www.behance.net/gallery/76724941/SPECTRUM19",
    buttonHeading: "Open Project",
  },
  {
    image: Project50,
    heading: "1000 Days of Everydays Art",
    link: "https://www.behance.net/gallery/162293951/1000-Day-of-Everyday-Art",
    buttonHeading: "Open Project",
  },
];

const Projects = () => {
  const [leave, setLeave] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();

  var tstart = 0;
  var deltaTouch = 0;
  const touch = (e) => {
    tstart = e.touches[0].clientY;
  };

  const swiping = (e) => {
    deltaTouch = tstart - e.changedTouches[0].clientY;
    if (deltaTouch < -30) {
      setLeave(1);
      setTimeout(() => {
        navigate("/skills", { state: location.pathname });
      }, 600);
    } else if (deltaTouch > 30) {
      setLeave(1);
      setTimeout(() => {
        navigate("/contact", { state: location.pathname });
      }, 600);
    }
  };

  const handleScroll = (e) => {
    if (e.deltaY < 0) {
      setLeave(1);
      setTimeout(() => {
        navigate("/skills", { state: location.pathname });
      }, 600);
    } else {
      setLeave(1);
      setTimeout(() => {
        navigate("/contact", { state: location.pathname });
      }, 600);
    }
  };

  const sliderRef = useRef(null);
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    slidesToShow: 1,
    speed: 500,
    slidesToScroll: 1,
    dots: false,
    arrows: false,
    initialSlide: 0,
    centerPadding: "600px",
    responsive: [
      {
        breakpoint: 1850,
        settings: {
          centerPadding: "550px",
        },
      },
      {
        breakpoint: 1750,
        settings: {
          centerPadding: "500px",
        },
      },
      {
        breakpoint: 1650,
        settings: {
          centerPadding: "450px",
        },
      },
      {
        breakpoint: 1550,
        settings: {
          centerPadding: "400px",
        },
      },
      {
        breakpoint: 1450,
        settings: {
          centerPadding: "350px",
        },
      },
      {
        breakpoint: 1350,
        settings: {
          centerPadding: "300px",
        },
      },
      {
        breakpoint: 1300,
        settings: {
          centerPadding: "350px",
        },
      },
      {
        breakpoint: 1200,
        settings: {
          centerPadding: "300px",
        },
      },
      {
        breakpoint: 1024,
        settings: {
          centerPadding: "250px",
        },
      },
      {
        breakpoint: 900,
        settings: {
          centerPadding: "200px",
        },
      },
      {
        breakpoint: 800,
        settings: {
          centerPadding: "150px",
        },
      },
      {
        breakpoint: 700,
        settings: {
          centerPadding: "100px",
        },
      },
      {
        breakpoint: 600,
        settings: {
          centerPadding: "80px",
        },
      },
      {
        breakpoint: 550,
        settings: {
          centerPadding: "60px",
        },
      },
      {
        breakpoint: 510,
        settings: {
          centerPadding: "40px",
        },
      },
      {
        breakpoint: 470,
        settings: {
          centerPadding: "40px",
        },
      },
    ],
  };
  const onWheelSlider = debounce((e, ref) => {
    if (!ref.current) return;

    if (e.deltaX > 0) {
      ref.current.slickNext();
    } else if (e.deltaX < 0) {
      ref.current.slickPrev();
    }
  }, 20);
  return (
    <div
      className={[classes.works_container, "section"].join(" ")}
      id="Projects"
      onWheel={(e) => handleScroll(e)}
      onTouchStart={(e) => touch(e)}
      onTouchEnd={(e) => swiping(e)}
    >
      <Navbar leave={leave} />
      <div className={classes.nav_space}></div>
      <motion.div
        initial={{
          opacity: 0,
          translateY: 0,
        }}
        animate={leave === 0 ? { opacity: 1 } : { opacity: 0 }}
        transition={{ duration: 0.3, delay: 0.2 }}
        className={classes.projects_heading}
      >
        Few of my recent Works...
      </motion.div>

      <div onWheel={onWheelSlider}>
        <Slider {...settings} ref={sliderRef}>
          {projects.map((project, index) => {
            return (
              <motion.div
                initial={{
                  opacity: 0,
                }}
                animate={leave === 0 ? { opacity: 1 } : { opacity: 0 }}
                transition={{ delay: 0.09 }}
                className={classes.slideClass}
              >
                <img
                  src={project.image}
                  alt="Display1"
                  className={classes.slideImage}
                />
                <div className={classes.slidecontainer}>
                  <div className={classes.heading}>{project.heading}</div>
                  <a
                    href={project.link}
                    target="_blank"
                    style={{
                      textDecoration: "none",
                      color: "#fff",
                    }}
                  >
                    <div className={classes.button}>
                      {project.buttonHeading}
                    </div>
                  </a>
                </div>
              </motion.div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
};

export default Projects;
