import React from 'react'
import classes from "./Resume.module.css"
import ResumeImg from "../../assets/images/Resume.svg"
import Resumepdf from "../../assets/pdfs/Resume.pdf"
import Close from "../../assets/images/close.svg"
import { Link } from 'react-router-dom'

const Resume = () => {
    const openInNewTab = (url:any) => {
        window.open(url, "_blank", "noopener,noreferrer");
    }
  return (
    <div className={classes.resume_container}>
        <Link to="/"><img src={Close} alt="Close" className={classes.close}/></Link>
        <img src={ResumeImg} alt="Resume" />
        <button className={classes.download} onClick={() => openInNewTab(Resumepdf)}>Download</button>
    </div>
  )
}

export default Resume