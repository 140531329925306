import React from "react";
import classes from "./Tag.module.css";
import { motion } from "framer-motion";

type Tag = {
  image: any;
  content: string;
  custom: boolean;
  index: number;
  animate?: object;
};

const Tag = React.forwardRef((props: Tag, ref: any) => {
  return (
    <motion.a
      initial={{
        opacity: 0,
        translateY: 50,
      }}
      href="mailto:sampark@vaibhav.wtf"
      animate={props.animate}
      transition={{ duration: 0.3, delay: props.index * 0.08 }}
      ref={ref}
      className={classes.tag_container}
      style={{ width: props.custom ? "fit-content" : "250px" }}
    >
      <img src={props.image} alt="Temp" />
      <div className={classes.content}>{props.content}</div>
    </motion.a>
  );
});

export default Tag;
