import React from 'react'
import { Link } from 'react-router-dom'
import classes from "./Notfound.module.css"
const Nofound = () => {
  return (
    <div className={classes.container}>
      <div className={classes.header}>Error 404</div>
      <div className={classes.description}>Don't worry, am lost too...</div>
      <Link to="/" className={classes.button}>Go Back Home</Link>
    </div>
  )
}

export default Nofound