import React, { useState, useEffect } from "react";
import classes from "./Navbar.module.css";
import MenuWhite from "../../assets/images/menu_white.svg";
import MenuBlack from "../../assets/images/menu_black.svg";
import Close from "../../assets/images/close.svg";

import { motion } from "framer-motion";
import { Link } from "react-router-dom";

type Navbar = {
  api?: any;
  color?: string;
  leave: number;
};
const Navbar = (props: Navbar) => {
  const [isOpen, setIsOpen] = useState(false);

  const openNavlinks = () => {
    setIsOpen(true);
  };
  const closeNavlinks = (index: number) => {
    setIsOpen(false);
    props.api.moveTo(index);
  };
  return (
    <>
      <motion.div
        initial={{
          opacity: 0,
          translateY: -50,
        }}
        animate={
          props.leave === 0
            ? {
                opacity: 1,
                translateY: 0,
              }
            : { opacity: 0, translateY: -50 }
        }
        transition={{
          duration: 0.6,
        }}
        className={classes.navbar_wrapper}
        id="menu"
      >
        <div className={classes.desktop_navlinks}>
          <Link
            to="/"
            className={
              props.color === "#ffffff"
                ? classes.navlink_white
                : classes.navlink
            }
            onClick={() => props.api.moveTo(1)}
          >
            Home
          </Link>
          <Link
            to="/about"
            className={
              props.color === "#ffffff"
                ? classes.navlink_white
                : classes.navlink
            }
            onClick={() => props.api.moveTo(2)}
          >
            About
          </Link>
          <Link
            to="/skills"
            className={
              props.color === "#ffffff"
                ? classes.navlink_white
                : classes.navlink
            }
            onClick={() => props.api.moveTo(3)}
          >
            Skills
          </Link>
          <Link
            to="/projects"
            className={
              props.color === "#ffffff"
                ? classes.navlink_white
                : classes.navlink
            }
            onClick={() => props.api.moveTo(4)}
          >
            Projects
          </Link>
          <Link
            to="/contact"
            className={
              props.color === "#ffffff"
                ? classes.navlink_white
                : classes.navlink
            }
            onClick={() => props.api.moveTo(5)}
          >
            Contact
          </Link>
        </div>
        <div className={classes.mobile_navlinks}>
          <img
            src={props.color === "#ffffff" ? MenuWhite : MenuBlack}
            alt="Menu"
            onClick={openNavlinks}
          />
        </div>
      </motion.div>
      <div
        className={classes.expanded_menu}
        style={{
          clipPath: isOpen
            ? "circle(2000px at 90% 10%)"
            : "circle(100px at 90% -20%)",
        }}
      >
        <img
          className={classes.close_icon}
          src={Close}
          alt="Close"
          onClick={() => closeNavlinks(0)}
        />
        <Link
          to="/"
          className={classes.navlink}
          onClick={() => closeNavlinks(1)}
        >
          Home
        </Link>
        <Link
          to="/about"
          className={classes.navlink}
          onClick={() => closeNavlinks(2)}
        >
          About
        </Link>
        <Link
          to="/skills"
          className={classes.navlink}
          onClick={() => closeNavlinks(3)}
        >
          Skills
        </Link>
        <Link
          to="/projects"
          className={classes.navlink}
          onClick={() => closeNavlinks(4)}
        >
          Projects
        </Link>
        <Link
          to="/contact"
          className={classes.navlink}
          onClick={() => closeNavlinks(5)}
        >
          Contact
        </Link>
      </div>
    </>
  );
};

export default Navbar;
