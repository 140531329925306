import React, { useEffect, useState, useRef } from "react";
import Navbar from "../../components/Navbar/Navbar";
import classes from "./Home.module.css";
import Kothi from "../../assets/images/kothi_illustration.png";
import Button from "../../components/Button/Button";
import Tag from "../../components/Tag/Tag";

import Software01 from "../../assets/images/skills_illustrator.svg";
import Software02 from "../../assets/images/skills_blender.svg";
import Software03 from "../../assets/images/skills_davinci.svg";
import Software04 from "../../assets/images/skills_aftereffects.svg";
import Software05 from "../../assets/images/skills_premierepro.svg";
import Software06 from "../../assets/images/skills_figma.svg";
import Software07 from "../../assets/images/skills_xd.svg";
import Software08 from "../../assets/images/skills_photoshop.svg";
import Software09 from "../../assets/images/skills_lightroom.svg";
import Software10 from "../../assets/images/skills_audition.svg";

import Skills01 from "../../assets/images/skills_illustration.svg";
import Skills02 from "../../assets/images/skills_branding.svg";
import Skills03 from "../../assets/images/skills_printdesign.svg";
import Skills04 from "../../assets/images/skills_digitalmarketing.svg";
import Skills05 from "../../assets/images/skills_applicationdesign.svg";
import Skills06 from "../../assets/images/skills_photomanipulation.svg";
import Skills07 from "../../assets/images/skills_colorgrading.svg";
import Skills08 from "../../assets/images/skills_posterdesign.svg";
import Skills09 from "../../assets/images/skills_videoediting.svg";
import Skills10 from "../../assets/images/skills_visualeffects.svg";
import Skills11 from "../../assets/images/skills_sounddesign.svg";
import Skills12 from "../../assets/images/skills_productpackging.svg";
import Skills13 from "../../assets/images/skills_userexperience.svg";
import Skills14 from "../../assets/images/skills_webdesign.svg";
import Skills15 from "../../assets/images/skills_merchandising.svg";

import Behance from "../../assets/images/contact_behance.svg";
import Dribbble from "../../assets/images/contact_dribbble.svg";
import GitHub from "../../assets/images/contact_github.svg";
import Instagram from "../../assets/images/contact_instagram.svg";
import LinkedIn from "../../assets/images/contact_linkedin.svg";
import Pinterest from "../../assets/images/contact_pinterest.svg";
import Twitter from "../../assets/images/contact_twitter.svg";
import Mail from "../../assets/images/contact_mail.svg";

// import Logo from "../../assets/images/vaibhav.wtf.svg";
import HomeSky from "../../assets/images/home_sky.svg";
// import HomeClouds from "../../assets/images/home_clouds.svg";
import Homehills from "../../assets/images/home_hills.svg";
import Homeland from "../../assets/images/home_lake_trees_me_aand_stuff.svg";
import Homelake from "../../assets/images/lake.svg";
import Texture from "../../assets/images/Texture/paper.jpg";
import Grain from "../../assets/images/Texture/grain.jpg";

// import * as animationData from "../../assets/images/loader.json";

import { useTypewriter } from "react-simple-typewriter";

// import Portfolio from "../../assets/pdfs/Portfolio.pdf";

import { motion, AnimatePresence } from "framer-motion";
import { Link, useLocation, useNavigate } from "react-router-dom";

// import { debounce } from "./helper";

const Home = () => {
  const hills = useRef(null);
  const land = useRef(null);
  const lake = useRef(null);

  const [leave, setLeave] = useState(0);
  const [wheelPosi, setWheelPosi] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();

  const { text } = useTypewriter({
    words: ["Hello.", "Hi.", "Namaste."],
    loop: 0,
  });

  var tstart = 0;
  var deltaTouch = 0;
  const touch = (e) => {
    tstart = e.touches[0].clientY;
  };

  const swiping = (e) => {
    deltaTouch = tstart - e.changedTouches[0].clientY;
    if (deltaTouch > 10) {
      setLeave(1);
      setWheelPosi(deltaTouch);
      setTimeout(() => {
        navigate("/about", { state: location.pathname });
      }, 1000);
    } 
  };

  const handleWheel = (e) => {
    // console.log(e);
    setLeave(1);
    setWheelPosi(e.deltaY);
    if (e.deltaY > 0) {
      setTimeout(() => {
        navigate("/about", { state: location.pathname });
      }, 1000);
    }
  };
  const handleButton = () => {
    setLeave(1);
    setWheelPosi(200);
    setTimeout(() => {
      navigate("/about", { state: location.pathname });
    }, 1000);
  };

  return (
    <>
      {/* <div className={classes.container}> */}
      <div
        id="Home"
        className={[classes.homescreen, "section"].join(" ")}
        onWheel={(e) => handleWheel(e)}
        onTouchStart={(e) => touch(e)}
        onTouchEnd={(e) => swiping(e)}
      >
        {/* <img className={classes.cloudimage} src={HomeClouds} alt="clouds" style={{display: prevScrollPos == 0 ? "none": "block"}}/> */}
        <motion.div
          initial={{ opacity: 0 }}
          animate={
            leave !== 0 && wheelPosi > 0 ? { opacity: 0 } : { opacity: 1 }
          }
          transition={
            leave !== 0 && wheelPosi > 0
              ? { type: "linear", duration: 0.9 }
              : { type: "linear", duration: 1.5 }
          }
          className={classes.clouds}
        ></motion.div>
        <img className={classes.skyimage} src={HomeSky} alt="sky" id="sky" />

        <div className={classes.illustration_flex}>
          <motion.img
            initial={{ translateY: -1000 }}
            animate={
              leave !== 0 && wheelPosi > 0
                ? { translateY: -1000 }
                : { translateY: 0 }
            }
            transition={{
              type: "linear",
              duration: 1.5,
            }}
            className={classes.hillimage}
            src={Homehills}
            alt="hills"
            ref={hills}
            id="hills"
          />
          <motion.img
            initial={{ translateY: -1000 }}
            animate={
              leave !== 0 && wheelPosi > 0
                ? { translateY: -2000 }
                : { translateY: 0 }
            }
            transition={{
              type: "linear",
              duration: 1.7,
            }}
            className={classes.lakeimage}
            src={Homelake}
            alt="lake"
            ref={lake}
            id="lake"
          />
        </div>
        <motion.img
          initial={{ translateY: -1000 }}
          animate={
            leave !== 0 && wheelPosi > 0
              ? { translateY: -2000 }
              : { translateY: 0 }
          }
          transition={{
            type: "linear",
            duration: 1.7,
          }}
          className={classes.landimage}
          src={Homeland}
          alt="land"
          ref={land}
          id="land"
        />
        <motion.img
          initial={{ opacity: 0 }}
          animate={
            leave !== 0 && wheelPosi > 0 ? { opacity: 0 } : { opacity: 0.2 }
          }
          transition={
            leave !== 0 && wheelPosi > 0 ? { delay: 0.2 } : { delay: 1 }
          }
          className={classes.texture}
          src={Texture}
          alt="Texture"
        />
        {/* <motion.img
          initial={{ opacity: 0 }}
          animate={
            leave !== 0 && wheelPosi > 0 ? { opacity: 0 } : { opacity: 0.4 }
          }
          transition={
            leave !== 0 && wheelPosi > 0 ? { delay: 0.2 } : { delay: 0.4 }
          }
          className={classes.grain}
          src={Grain}
          alt="Grain"
        /> */}
        <motion.div
          initial={{ opacity: 0, translateY: 0 }}
          animate={
            leave !== 0 && wheelPosi > 0
              ? { opacity: 0, translateY: 100 }
              : { opacity: 1 }
          }
          transition={{
            type: "linear",
            duration: 0.7,
            delay: leave !== 0 && wheelPosi > 0 ? 0 : 0.7,
          }}
          className={classes.introtext}
          id="introtext"
        >
          {text}
        </motion.div>
        <div className={classes.scroll_container} onClick={handleButton}>
          <div className={classes.scroller}></div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
};

export default Home;
